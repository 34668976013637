.notification-panel {
  --notificationPanelWidth: 340px;
  right: 0;
  top: 0;
  width: var(--notificationPanelWidth);
  height: 100vh;
  position: fixed;
  background: #f5f5f5;
  box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
  overflow: hidden;
  transition: all 150ms;
  transform: translateX(var(--notificationPanelWidth));
  z-index: 999;

  &.visible {
    transition: all 150ms;
    transform: translateX(0);
  }

  header {
    font-family: "Poppins", sans-serif;
    padding: 8px 12px;
    position: relative;
    text-align: center;
    display: grid;
    grid-template-columns: 28px 1fr 28px;
    align-items: center;
    border-bottom: 1px solid rgba(9, 30, 66, 0.13);
    h3 {
      font-size: 18px;
      margin: 0;
    }
    button {
      background: transparent;
      border: none;
    }
    // grid positioning
    button.filter {
      grid-column: 1;
      grid-row: 1;
    }
    button.close {
      grid-column: 3;
      grid-row: 1;
    }
  }
}
