.search-session-modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0% 10% 10% 0%;
    height: 100vh;
    width: 100%;
    padding: 2rem;
    /* background-color: rgb(155, 155, 204); */
    backdrop-filter: blur(10px);
    z-index: 100000;
}