.contact-us-container {
    .right-container {
        .contact-us-form {
            .textfield-style {
                border-color: lightgrey;
                &::placeholder {
                    color: grey;
                }
            }
        }
    }
}
