$fontsizeamount: (10, 12, 13, 14, 15, 16, 17, 18, 20, 25, 30, 35, 40, 45); // Adjust this to include the Font Size amounts you need.
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right,); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .m-x-#{$space} {
      margin: 0px #{$space}px !important;
    }
    .m-y-#{$space} {
      margin:  #{$space}px 0px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    .p-x-#{$space} {
      padding: 0px #{$space}px !important;
    }
    .p-y-#{$space} {
      padding:  #{$space}px 0px !important;
    }
  }
  
}
@each $size in $fontsizeamount {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
}