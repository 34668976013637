@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit; 
}
a:hover {
  color: #535bf2;
} */

/* for scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* for scrollbar */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  text-decoration: none !important;
  font-family: "avenir";
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f6fa;
  scroll-behavior: smooth;
  cursor: default;
}

/* Mui section---------- */
.css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
/* styles for */
:root {
  --primary-color: #00b0f0;
  --secondary-color: #fff;
  --ternary-color: #000000;
  --text-primary-color: #000000;
  --text-hover-color: #9b9696a7;
  --text-secondary-color: #000000;
  --text-primary-complementery: #ffffff;
  --text-recording: #ff0101;
  --bg-primary: #f5f5f9;
  --bg-secondary: #ebebeb;
  --bg-ternary: #ebebeb;
  --bg-color-modal: #fcfcfc;
  --svgIconColor: #000000;
  --svgTubeColor: #a79f9f;
  --svgManikinBodyColor: #d4dadd;
  --svgLeftLungColor: #f9f9fa;
  --headingtext2: var(--primary-color);
  --borderLeft: #ffffff;
  --modalInputBg: #f0f0f0;
  --modalButtonBg: #00b0f0;
  --inputBorderColor: #00b0f0;
  --loginBg: white;
  --svgContainerBg: #d3e3fd;
}

/* styles for darkmode */
.dark {
  --primary-color: #ffffff;
  --ternary-color: #0b6eff;
  --text-hover-color: #0b6dff69;
  --secondary-color: #000000;
  --text-secondary-color: #00b0f0;
  --bg-primary: #020202;
  --bg-secondary: #181717;
  --bg-ternary: #131212;
  --bg-color-modal: #333232;
  --text-primary-color: #ffffff;
  --text-primary-complementery: #000000;
  --svgIconColor: #1589ff;
  --svgLaryColor: #ffffff;
  --svgManikinBodyColor: #9b9696d0;
  --svgLeftLungColor: #cec8c8a6;
  --borderLeft: #385723;
  --modalInputBg: #787878;
  --modalButtonBg: #00b0f0;
  --inputBorderColor: #00b0f0;
  --loginBg: linear-gradient(180deg, #222a35 3%, rgba(1, 1, 1, 1) 40%);
  --svgContainerBg: #888e99;
}

.MuiTabs-scroller {
  display: flex !important;
  align-items: center !important;
}

.slick-arrow {
  background: black !important;
}

.tab-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.tab-slider-arrow {
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
}

.tab-slider-arrow.visible {
  display: block;
}

.tab-slider-tabs-container {
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 10px;
}

.tab-slider-arrow {
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
  display: none;
}

.tab-slider-arrow.visible {
  display: block;
}

.tab-slider-tabs {
  display: inline-block;
}

.tab-slider-tab {
  padding: 10px 20px;
  display: inline-block;
  position: relative;
}

.close_icon {
  position: absolute;
  right: 0;
  top: 0;
}

.last-tab {
  position: sticky;
  left: 0;
  background-color: #ffffff;
  z-index: 1;
}

::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.defDesc {
  text-align: justify;
}

@font-face {
  font-family: "avenir"; /*Can be any text*/
  src: local("font"),
    url("./fonts/062ae68a-1bea-4e84-aebf-2059092e4655/MyWebfontsKit/webFonts/AvenirNextProRegular/font.woff2")
      format("truetype");
}

/* Custom scrollbar styles for tabs */
.tabs-scrollbar {
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
}

/* Webkit-based browsers */
.tabs-scrollbar::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.tabs-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.tabs-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded edges */
}

.tabs-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover effect on the scrollbar thumb */
}

/* Custom scrollbar styles for details */
.tabdetails-scrollbar {
  overflow-y: auto; /* Enable horizontal scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
}

/* Webkit-based browsers */
.tabdetails-scrollbar::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.tabdetails-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.tabdetails-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded edges */
}

.tabdetails-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover effect on the scrollbar thumb */
}
