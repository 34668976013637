.play-and-learn-container {
  .manikin-svg-container {
    position: relative;
    border-radius: 0px;
    background: var(--bg-color1);
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      background-color: #f2f2f2;
    }
    #manikin {
      z-index: 2;
    }
  }
  .left-pane {
    .top-left-pane {
      .video-player {
        position: relative;

        .header-container {
          display: flex;
          justify-content: end;
          position: absolute;
          bottom: 0;
          padding: 0.5rem;
          z-index: 3;
          width: 100%;
          .pnl-device-select {
            background-color: transparent;
            border: none;
            font-weight: bold;
            color: black;
            outline: none;
            font-size: 0.875rem;
            option {
              font-size: 1rem;
            }
          }
          .clock {
            .timer {
              .time {
                color: black;
                font-weight: 500;
              }
            }
            button {
              height: auto;
            }
          }
        }
        .action-btns-container {
          z-index: 3;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          button {
            border: none;
            outline: none;
            background-color: transparent;
            color: black;
            border-radius: 10px;
            font-size: 1.2rem;
            padding: 2px 10px;
          }
        }
        svg#manikin {
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }
      }
    }
    .bottom-left-pane {
      .play-learn-tabs-container {
        // .play-learn-tabs {
        //     min-height: 36px;
        //     .MuiButtonBase-root {
        //         padding: 8px 10px;
        //         min-height: 36px;
        //     }
        // }
        .pnl-tab-panel {
          // height: calc(100% - 45px);
          // overflow-y: auto;
          .tab-panel-div {
            .sensor-data-list {
              .sensor-data-row {
                grid-template-columns: repeat(4, 1fr);
                column-gap: 5px;
                border-bottom: 1px solid lightgrey;
                padding: 10px 0px;
              }
            }
          }
        }
      }
    }
  }
  .right-pane {
    position: relative;
    .nav-container {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 5px;
      right: 10px;
      button {
        outline: none;
        border: none;
        font-size: 1.3rem;
        background-color: transparent;
        transition: color 0.3s;
        &:hover {
          color: var(--bs-blue);
        }
      }
    }
  }
  .sash-container {
    .sash {
      &.sash-vertical {
        &::after {
          // content: " ";
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 1.5ch;
          content: "||";
          text-align: center;
          background-color: #bdbdbd;
          border-radius: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 40px;
          padding-bottom: 5px;
        }
      }
      &.sash-horizontal {
        &::after {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 1.5ch;
          padding: 20px 8px 22.5px;
          content: "||";
          text-align: center;
          background-color: #bdbdbd;
          border-radius: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
}

// .MuiTabs-scroller {
//   display: flex;
//   align-items: center;
// }
