@mixin displayCode($para1) {
  display: flex;
  justify-content: $para1;
  align-items: center;
}

$primary-color: #ffffff;
$ternary-color: #0b6eff;
$bg-primary: #020202;
$bg-secondary: #252525;
$bg-color-modal: #333232;
$text-primary-color: #ffffff;
$text-primary-complementary: #000000;

#auth_cont {

  @include displayCode(center);
  width: 100vw;
  height: 100vh;

  .auth_subcontainer {
    @include displayCode(center);
    width: 100vw;
    height: 100vh;
    padding: 0 5rem 0 0;
    gap: 5rem;



    .forms_parentcontainer {
      @include displayCode(center);
      flex-basis: 80%;

      .forms_parentubcontainer {
        width: 100%;
        height: max-content;

        .company_title {
          @include displayCode(start);
          font-size: 1.2rem;
          font-weight: 400;
          color: var(--text-primary-color);
          padding-bottom: 1rem;
          gap: 0.5rem;

          #logo_img {
            background: #fff;
            width: 200px;
            padding: 2rem;
            border-radius: 2rem;
          }
        }

        .sysAdmin_cont {

          @include displayCode(center);
          flex-direction: column;
          width: 100%;
          height: max-content;
          gap: 3rem;

          .inp_maincont {
            width: 100%;
            height: max-content;

            .inp_cont {
              @include displayCode(start);
              width: 100%;
              height: max-content;
              border-bottom: 1px solid;
              gap: 15px;
              position: relative;
              font-size: 1.25rem;
              font-weight: 600;

              &.dark_cont {
                border-bottom: 1px solid $ternary-color;
                color: rgb(168, 169, 170);

                &:focus-within {
                  color: white;
                  border-bottom: 4px solid $ternary-color;
                }
              }

              .inputfield {
                width: 100%;
                border: none;
                outline: none;

                &:focus {
                  border: none;
                  outline: none;
                }
              }

              .dark {
                width: 100%;
                border: none;
                outline: none;
                background-color: $bg-primary;

                &:focus {
                  border: none;
                  outline: none;
                  background-color: $bg-primary;
                }
              }

            }

            .suggestion {
              color: red;
              font-size: 14px;
            }
          }

          .otp_cont {
            width: 100%;
            height: max-content;

            .otp_inp_cont {
              width: 100%;
              @include displayCode(start);
              gap: 1.25rem;
              font-size: 1.25rem;
              font-weight: 600;

              .otp {}

              .input {
                width: 100%;
                border-bottom: 1px solid;

                .otp_input {
                  width: 100%;
                  border: none;
                  outline: none;

                  &:focus {
                    border: none;
                    outline: none;
                  }
                }

              }
            }

            .regenerate_link {
              width: max-content;
              padding-top: 2px;
              font-size: 0.875rem;
              text-decoration: underline !important;
              color: var(--primary-color);

              &:hover {
                cursor: pointer;
              }
            }
          }

          #btn_container {
            @include displayCode(end);
            width: 100%;
            height: max-content;
            gap: 5px;
            font-size: 1.25rem;

            .reset_btn,
            .next_btn {
              padding: 0.5rem 1.5rem;
              color: #000000;

              &:hover {
                background-color: var(--primary-color);
                color: #fff;
                cursor: pointer;
              }
            }

            .dark {
              padding: 0.5rem 1.5rem;
              color: #00B0F0;

              &:hover {
                background-color: #00B0F0;
                color: #000000;
                cursor: pointer;
              }
            }
          }

        }

      }

    }
  }
}

@keyframes slider {

  0%,
  25%,
  100% {
    left: 0
  }

  30%,
  55% {
    left: -100%
  }

  60%,
  85% {
    left: -200%
  }
}

.eyeicon {
  position: absolute;
  right: 0.2rem !important;
}


@media (max-width:900px) {

  .forms_parentcontainer {
    @include displayCode(center);
    width: 100%;
    padding: 0 2rem;
  }

  .company_title {
    padding-bottom: 2rem !important;
  }

  .auth_subcontainer {
    @include displayCode(center);
    flex-direction: column;
    gap: 2rem !important;
    padding: 0 !important;
  }



  // .img_cont {
  //     width: 100% !important;
  //     @include displayCode(center);
  //     // width:500px !important;
  //     height: 400px !important;

  //     img {
  //         object-fit: fill;
  //         width: 100% !important;
  //         height: 100% !important;
  //     }



  // }
}

@media (max-width:600px) {

  .company_title {
    padding-bottom: 0.5rem !important;
  }

  .otp_inp_cont {
    @include displayCode(start);
    gap: 0.5rem !important;

    .otp {
      font-size: 0.875rem !important;
    }
  }

}

@media (max-width:400px) {
  .sysAdmin_cont {
    gap: 1rem !important;
  }
}

.pswInp {
  width: 85% !important;
}


.tagline_cont {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: absolute;
  right: -1.4rem;
  // right: 1.6rem; //position changed
  bottom: 15.5rem;
  z-index: 999;
  overflow: visible;

  .top_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    height: max-content;
    padding: 0 0 0 1rem;
    background: rgba(0, 0, 0, 0.691);
    border-radius: 0.5rem;

    .com_title {
      font-size: 3.5rem;
      font-weight: 700;
      color: goldenrod;
      line-height: 2.5rem !important;
      padding-top: 0.5rem;
    }

    .box1 {
      width: 300px;
      overflow-x: visible;
      color: var(--primary-color);
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 2.3rem !important;

    }
  }



  .box2 {
    display: flex;
    align-items: center;
    width: 400px;
    overflow-x: visible;
    height: 45px !important;
    background: rgba(0, 0, 0, 0.691);
    border-radius: 0.5rem;
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.3rem !important;
    white-space: nowrap;
    padding: 0 1rem;
  }

  .logotext {
    width: max-content;
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.691);
    border-radius: 0.5rem;
    padding: 0 1rem;
  }
}

@mixin displayCode($para1) {
  display: flex;
  justify-content: $para1;
  align-items: center;
}

$primary-color: #ffffff;
$ternary-color: #0b6eff;
$bg-primary: #020202;
$bg-secondary: #252525;
$bg-color-modal: #333232;
$text-primary-color: #ffffff;
$text-primary-complementary: #000000;

#auth_cont {
  @include displayCode(center);
  width: 100vw;
  height: 100vh;

  .auth_subcontainer {
    @include displayCode(center);
    width: 100vw;
    height: 100vh;
    padding: 0 5rem 0 0;
    gap: 5rem;

    .forms_parentcontainer {
      @include displayCode(center);
      flex-basis: 80%;

      .forms_parentubcontainer {
        width: 100%;
        height: max-content;

        .company_title {
          @include displayCode(start);
          font-size: 1.2rem;
          font-weight: 400;
          color: var(--text-primary-color);
          padding-bottom: 1rem;
          gap: 0.5rem;

          #logo_img {
            background: #fff;
            width: 200px;
            padding: 2rem;
            border-radius: 2rem;
          }
        }

        .sysAdmin_cont {
          @include displayCode(center);
          flex-direction: column;
          width: 100%;
          height: max-content;
          gap: 3rem;

          .inp_maincont {
            width: 100%;
            height: max-content;

            .inp_cont {
              @include displayCode(start);
              width: 100%;
              height: max-content;
              border-bottom: 1px solid;
              gap: 15px;
              position: relative;
              font-size: 1.25rem;
              font-weight: 600;

              &.dark_cont {
                border-bottom: 1px solid $ternary-color;
                color: rgb(168, 169, 170);

                &:focus-within {
                  color: white;
                  border-bottom: 4px solid $ternary-color;
                }
              }

              .inputfield {
                width: 100%;
                border: none;
                outline: none;

                &:focus {
                  border: none;
                  outline: none;
                }
              }

              .dark {
                width: 100%;
                border: none;
                outline: none;
                background-color: $bg-primary;

                &:focus {
                  border: none;
                  outline: none;
                  background-color: $bg-primary;
                }
              }
            }

            .suggestion {
              color: red;
              font-size: 14px;
            }
          }

          .otp_cont {
            width: 100%;
            height: max-content;

            .otp_inp_cont {
              width: 100%;
              @include displayCode(start);
              gap: 1.25rem;
              font-size: 1.25rem;
              font-weight: 600;

              .otp {}

              .input {
                width: 100%;
                border-bottom: 1px solid;

                .otp_input {
                  width: 100%;
                  border: none;
                  outline: none;

                  &:focus {
                    border: none;
                    outline: none;
                  }
                }
              }
            }

            .regenerate_link {
              width: max-content;
              padding-top: 2px;
              font-size: 0.875rem;
              text-decoration: underline !important;
              color: var(--primary-color);

              &:hover {
                cursor: pointer;
              }
            }
          }

          #btn_container {
            @include displayCode(end);
            width: 100%;
            height: max-content;
            gap: 5px;
            font-size: 1.25rem;

            .reset_btn,
            .next_btn {
              padding: 0.5rem 1.5rem;
              color: #000000;

              &:hover {
                background-color: var(--primary-color);
                color: #fff;
                cursor: pointer;
              }
            }

            .dark {
              padding: 0.5rem 1.5rem;
              color: #00b0f0;

              &:hover {
                background-color: #00b0f0;
                color: #000000;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slider {

  0%,
  25%,
  100% {
    left: 0;
  }

  30%,
  55% {
    left: -100%;
  }

  60%,
  85% {
    left: -200%;
  }
}

.eyeicon {
  position: absolute;
  right: 0.2rem !important;
}

@media (max-width: 900px) {
  .forms_parentcontainer {
    @include displayCode(center);
    width: 100%;
    padding: 0 2rem;
  }

  .company_title {
    padding-bottom: 2rem !important;
  }

  .auth_subcontainer {
    @include displayCode(center);
    flex-direction: column;
    gap: 2rem !important;
    padding: 0 !important;
  }

  // .img_cont {
  //     width: 100% !important;
  //     @include displayCode(center);
  //     // width:500px !important;
  //     height: 400px !important;

  //     img {
  //         object-fit: fill;
  //         width: 100% !important;
  //         height: 100% !important;
  //     }

  // }
}

@media (max-width: 600px) {
  .company_title {
    padding-bottom: 0.5rem !important;
  }

  .otp_inp_cont {
    @include displayCode(start);
    gap: 0.5rem !important;

    .otp {
      font-size: 0.875rem !important;
    }
  }
}

@media (max-width: 400px) {
  .sysAdmin_cont {
    gap: 1rem !important;
  }
}

.pswInp {
  width: 85% !important;
}

.tagline_cont {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: absolute;
  right: -1.4rem;
  // right: 1.6rem; //position changed
  bottom: 15.5rem;
  z-index: 999;
  overflow: visible;

  .top_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    height: max-content;
    padding: 0 0 0 1rem;
    background: rgba(0, 0, 0, 0.691);
    border-radius: 0.5rem;

    .com_title {
      font-size: 3.5rem;
      font-weight: 700;
      color: goldenrod;
      line-height: 2.5rem !important;
      padding-top: 0.5rem;
    }

    .box1 {
      width: 300px;
      overflow-x: visible;
      color: var(--primary-color);
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 2.3rem !important;
    }
  }

  .box2 {
    display: flex;
    align-items: center;
    width: 400px;
    overflow-x: visible;
    height: 45px !important;
    background: rgba(0, 0, 0, 0.691);
    border-radius: 0.5rem;
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.3rem !important;
    white-space: nowrap;
    padding: 0 1rem;
  }

  .logotext {
    width: max-content;
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 400;
    background: var(--bg-primary);
    border-radius: 0.5rem;
    padding: 0 1rem;
  }
}