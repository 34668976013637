.img-slider {
  position: relative;
}

.slide-container {
  position: relative;
  width: 60vw;
  margin: auto;
}

.slide {
  display: none;
  transition: transform;
}

.slide img {
  vertical-align: middle;
  height: 100vh;
  width: 100%;
}
.fade {
  animation-name: fade;
  animation-duration: 5s;
}

@keyframes fade {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
#_image-slider {
  width: 50vw;
  height: 100vh;
  overflow: hidden;
}

#_image-slider img {
  vertical-align: middle;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
