@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --red: rgb(202, 102, 102);
  --red300: #ffa0a0;
  --white: #fff;
  --blue: #3b2983;
  --blue700: #1c88ff;
  --border: 1px solid #f3f3f3;
  --radius30: 30px;
  --radius10: 10px;
  --radius4: 4px;
  --brekpoint-xs: 767px;
}

.box-header-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-header-sec .box-header {
  font-size: 22px;
  font-family: "Poppins";
}

.cursor-pointer {
  cursor: pointer;
}

.c-primary {
  color: var(--blue);
}

.font-poppins {
  font-family: "Poppins";
}

.sec-header {
  border-radius: 4px;
  text-align: start;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Poppins";
}

.bg-header {
  background: #fdf6f6;
  line-height: 35px;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 13px;
  font-family: "Poppins";
}

.box-container {
  border: var(--border);
  border-radius: var(--radius30);
  width: 100%;
  overflow: hidden;
  background-color: var(--white);
}

.box-style {
  border: var(--border);
  border-radius: var(--radius10);
  padding: 15px;
  width: 100%;
  overflow: hidden;
  background-color: var(--white);
}

.logo-for-login {
  width: 11rem;
}

.btn-submit {
  border-radius: var(--radius4);
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 6px 10px;
  outline: none !important;
  margin-bottom: 15px;
  color: white;
  background-color: var(--blue);
}

.btn-submit:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.btn-submit:active {
  box-shadow: none;
}

.btn-outline-cancel {
  border-radius: var(--radius4);
  border: 1px solid var(--red300);
  width: 100%;
  font-size: 13px;
  padding: 3px 10px;
  outline: none !important;
  margin-bottom: 15px;
  color: var(--red300);
  background-color: #fff;
  transition: all 0.3s;
}

.btn-outline-cancel:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  background-color: var(--red300);
  color: white;
}

.btn-outline {
  border-radius: var(--radius4);
  border: 1px solid var(--blue);
  width: 100%;
  font-size: 13px;
  padding: 3px 10px;
  outline: none !important;
  margin-bottom: 15px;
  color: outline;
  background-color: #fff;
  transition: all 0.3s;
}

.btn-outline:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  background-color: var(--blue);
  color: white;
}

.icon-btn-green {
  border: 1px solid #b9ffb4;
  background: #e1ffe4;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #a0e7a0;
  transition: all 0.3s;
  outline: none;
}

.icon-btn-green:hover {
  background-color: #49cb56;
  color: white;
}

.icon-btn-red {
  border: 1px solid #ffe8e8;
  background: #fff6f6;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #f7aeae;
  outline: none;
  transition: all 0.3s;
}

.icon-btn-red:hover {
  background-color: #ca282e;
  color: white;
}

.input-label {
  color: black;
  font-size: 13px;
}

.textfield-style {
  border: var(--border);
  outline: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  background: #fdfdfd;
  width: 100%;
}

.textfield-style::placeholder {
  color: #d9d9d9;
}

.border-solid-left {
  border-left: 2px solid #eee;
  margin: 20px 0px 10px 0px;
}

.border-dotted-bottom {
  border-bottom: 2px dashed #eee;
  margin: 20px 0px 10px 0px;
}

.table-design {
  width: 100%;
  border-collapse: collapse;
  font-family: "Poppins";
  font-size: 14px;
}

.table-design thead th {
  background-color: #f7f7f7;
  font-weight: bold;
}

.table-design th,
.table-design td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-design tbody tr:hover {
  background-color: #f7f7f7;
}

.table-design tbody td {
  vertical-align: middle;
}

.table-design tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

/* sidemenu>>>>>>>>>>>>>>>> */
/* admin container ------------ */

a {
  text-decoration: none;
}

.main-container {
  display: flex;
}

main {
  flex: 1;
}

.title {
  font-size: 30px;
}

.sidebar {
  /* background: rgb(0, 7, 61); */
  background: var(--secondary-color);
  /* background-color:red; */
  color: var(--ternary-color);
  height: 100vh;
  position: sticky;
  top: 0;
  box-shadow: 2px 0px 3px 0.5px #c9c8c84a inset;
  z-index: 10000;
}

.routes {
  height: 100%;
  color: #111;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 15px 10px; */
  padding: 10px 15px 10px 15px;
}

.bars:hover {
  cursor: pointer;
}

.logo {
  font-size: 18px;
  line-height: 0;
  margin-bottom: 0px;
}

.logo img {
  width: 7rem;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px 15px 10px 15px;
}

.search input {
  /* width: 160px; */
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  /* padding: 5px 10px; */
  border-bottom: 0.1px solid #c9c8c84a !important;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ternary-color);
  gap: 10px;
  padding: 10px 0;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-transform: capitalize;
}

.link:hover {
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* background: rgb(45, 51, 89); */
  fill: red;
}

.active {
  /* background: rgb(45, 51, 89); */
  color: var(--secondary-color);
  fill: red;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.Intubation-list .items {
  font-family: "Poppins";
  font-size: 12px;
}

.scroll-box {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow: auto;
}

/* Scroll style start */
/* width */
.scroll-box::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scroll-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-box::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scroll style End */
