@import "../../../../sass/variable";

.manikin-details-main-content-container {
    width: 100%;
    height: auto;
    min-height: 500px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .left-actions-container {
        width: 25%;
        padding-right: 15px;
        border-right: 1px solid grey;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    .right-container {
        flex: 1;
        padding-left: 15px;
    }
    .allocation-btn {
        border: 1px solid grey;
        &.active {
            background-color: $blue;
            color: white;
        }
    }
}
