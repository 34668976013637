@import "variable";
@import "helper";

.table-style {
  // padding: 15px;
  // border: 1px solid #eee;

  .table {
    font-family: "Poppins";
    font-size: 13px;

    .table-select {
      color: white;
      background: none;
      font-size: 13px;
      font-family: "Poppins";
      border: none;
      font-weight: 700;
      cursor: pointer;
      outline: none;

      option {
        color: #0e0e0e;
      }
    }

    thead tr,
    th {
      background-color: #4472c4 !important;
      color: white;
    }
  }
}

// @import "breakpoints";
.profile-avater-square {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  /* border: 1px solid #afafaf; */
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  color: #bfbfbf;
  letter-spacing: 1px;
}

// Auth section ------->

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: relative;

  .box-one {
    border-bottom-left-radius: 135px;
    width: 135px;
    height: 135px;
    background-color: #1c88ff;
    position: absolute;
    /* border-radius: 64px; */
    top: 0;
    right: 0px;
    z-index: -2;
    background: linear-gradient(45deg, #ff8989, transparent);
  }

  .box-container {
    width: 998px;
    margin: auto;
  }

  .img-sec {
    height: 100%;
    align-items: center;
    display: flex;
  }

  .form-sec {
    width: 65%;
    padding: 20px;
    text-align: start;
    height: 72vh;
    margin: auto;

    .form-sub-header {
      // color: #c9252b;
      color: #f1960f;
      font-weight: 500;
      font-style: italic;
    }
  }
}

.auth-form-container {
  // width: 65%;
  // margin: auto;

  .form-header {
    text-align: start;
    color: $blue;
    font-size: 15px;
  }

  .login-input {
    border-radius: $radius4;
    border: $border;
    width: 100%;
    font-size: 16px;
    padding: 6px 10px;
    color: #0e0e0e;
    outline: none;
    margin-bottom: 15px;

    &::placeholder {
      color: rgb(196, 196, 196);
      font-size: 14;
    }
  }
}

.top-navber {
  height: 60px;
  // background-color: #00073d;
  background-color: #1c88ff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 111;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 0;

  // .topnav-logo {
  //     width: 210px;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     height: 100%;

  //     img {
  //         margin: 0 auto;
  //         width: 8rem;
  //     }

  //     .topnav-logo-sub-title {
  //         font-size: 9px;
  //         color: #e5b710;
  //         margin-bottom: 0;
  //         font-style: italic;
  //     }
  // }
}

.admin-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;

  .left-panel {
    background-color: #ffffff;
    box-shadow: inset 1px 0 10px 0 rgba(0, 0, 0, 0.04);
    position: fixed;
    width: 210px;
    height: calc(100vh - 60px);
    padding-top: 15px;
    overflow-y: auto;
  }

  .right-panel {
    width: calc(100% - 210px);
    margin-left: 210px;
    padding: 20px;
  }
}

// Left Menu Bar start-------
.left-menubar {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .left-menu-items {
    a {
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      margin: 7px;
      letter-spacing: 0;
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      text-decoration: none;
      color: #818181;
    }
  }
}

.is-active {
  background-color: #efeef9 !important;
  color: #1c2c9f !important;
  border: 1px solid #d6ddff;
}

// Left Menu Bar end-------
// Student Landing page start-------
.side-img-container {
  overflow: hidden;
  width: 9rem;
  height: 10rem;
  border-radius: 4px;
  position: relative;

  .side-img-list {
    width: inherit;
    height: inherit;
    object-fit: unset;
  }

  .side-img-title {
    position: absolute;
    z-index: 6;
    bottom: 0;
    background-image: linear-gradient(#8f8f8f80, #2e2e2ebf);
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      color: white;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

// Student Landing page end-------
// Play & Learn page start-------
.play-learn-sec {
  display: flex;
  align-items: inherit;
  gap: 10px;

  .timer {
    background-color: #f5f5f5;
    border: 5px solid #e9e6e6;
    border-radius: 4px;
    flex-basis: 140px;
    flex-shrink: 0;

    .time {
      color: #9b9b9b;
      text-align: center;
      white-space: nowrap;
      margin-bottom: 0px;
      font-family: "Poppins";
    }
  }
}

.btn-side-icon-group {
  position: fixed;
  top: 40%;
  right: 0%;
  width: 140px;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    text-transform: uppercase;
    padding: 2px;
    font-size: 10px;
    font-family: "Poppins";
    margin: 1px;
    text-align: left;
    border-radius: 50px 0px 0px 50px;
    transform: translate(110px, 0px);
    transition: all 0.5s;

    svg {
      margin-right: 6px;
      background-color: white;
      width: 20px;
      height: 20px;
      color: #0e0e0e;
      text-align: center;
      padding: 2px;
      border-radius: 50%;
      transition: all 0.5s;
      box-shadow: 0px 2px 4px #8c8c8c;
    }

    &:hover {
      transform: translate(0px, 0px);
      background-color: #2c80d3;
      color: white;

      svg {
        transform: rotate(360deg);
      }
    }
  }
}

.play-learn {
  display: grid;
  width: auto;
  grid-gap: 10px;
  grid-template-columns: 50% 1fr 1fr;
  grid-template-rows: repeat(3, 200px);
  grid-template-areas:
    "video-player content-1 content-2"
    "video-player content-1 content-2";
}

.list-group li {
  span {
    word-break: break-word;
  }
}

.video-player {
  grid-area: video-player;
  overflow: hidden;
}

.content-1 {
  grid-area: content-1;
  grid-row: 1 / 3;
}

.content-2 {
  grid-area: content-2;
  grid-row: 1 / 3;
}

// .content-4 {
//   grid-area: content-4;
//   background-image: url("https://upload.wikimedia.org/wikipedia/commons/c/ce/Elementary_classroom_in_Alaska.jpg");
//   background-size: contain;
// }

.item {
  color: #ffffff;
  border-radius: var(--radius10);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.btn-submit {
  background-color: var(--bs-blue);
  &.running {
    background-color: var(--red);
  }
}

.video-player {
  // background-image: linear-gradient(203deg, #3edfd7 0%, #29a49d 90%);
  background-image: linear-gradient(180deg, #e6e6e6 0%, #d9d9d9 50%);

  img {
    width: 100%;
    height: 400px;
    object-fit: fill;
  }
}

.gradient-bg {
  background-image: linear-gradient(130deg, #6c52d9 0%, #1eaafc 85%, #3edfd7 100%);
}

// Play & Learn page end-------

// CUSTOM FONT SIZES
.fs-1_2rem {
  font-size: 1.2rem;
}

.manikin-cont{

  img{
    width: 100%;
    height:100%;
    object-fit: fill;
  }
}
